<template>
  <div ref="invoiceTemplate" class="px-6 px-md-12 py-6 py-md-12">
    <table id="header">
      <tr>
        <th id="header-col-1"></th>
        <th id="header-col-2"></th>
      </tr>
      <tr>
        <td id="logo-wrapper">
          <img :src="organizationLogo" alt="organization Logo" id="logo" />
        </td>
        <td id="document-type-wrapper">
          <p id="document-type">{{ documentTitle }}</p>
          <br />
          <b>From</b><br />
          <b>{{ company_from }}</b
          ><br />
          {{ address_from }}<br />
          {{ zip_from }}, {{ city_from }}<br />
          {{ country_from }}<br />
          <!-- {{ sender_custom1 }}<br />
          {{ sender_custom2 }}<br />
          {{ sender_custom3 }} -->
        </td>
      </tr>
    </table>
    <br />
    <hr />
    <table id="receiver-details-wrapper">
      <tr>
        <th id="receiver-details-wrapper-col-1"></th>
        <th id="receiver-details-wrapper-col-2"></th>
      </tr>
      <tr>
        <td>
          <b>Bill To:</b><br />
          <b>{{ company_to }}</b>
          <br />
          {{ address_to }}<br />
          {{ zip_to }}, {{ city_to }}<br />
          {{ country_to }} <br />
          <!-- {{ client_custom1 }}<br />
          {{ client_custom2 }} <br />
          {{ client_custom3 }} -->
        </td>
        <td id="details-value-wrapper">
          <b>number: </b>{{ invoicenumber }}<br />
          <b>date: </b>{{ date }}<br />
          <b>due-date: </b>{{ dueDate }}
        </td>
      </tr>
    </table>

    <br />
    <br />
    <table id="products-table">
      <thead>
        <tr>
          <th id="products-table-col-1"></th>
          <th id="products-table-col-2"></th>
          <th id="products-table-col-3"></th>
          <th id="products-table-col-4"></th>
        </tr>
        <tr>
          <td style="padding-left: 5px"><b>Items</b></td>
          <td><b>Quantity</b></td>
          <td><b>Price</b></td>
          <td><b>Total</b></td>
        </tr>
        <tr>
          <td colspan="4">
            <hr />
          </td>
        </tr>
      </thead>
      <tbody v-for="(line, index) in lines" :key="index">
        <tr>
          <td class="pl-2">{{ line.description }}</td>
          <td align="center">{{ line.quantity }}</td>
          <td>{{ line.price | currency }}</td>
          <td>{{ (line.quantity * line.price) | currency }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">
            <hr />
          </td>
        </tr>
      </tfoot>
    </table>
    <br />
    <table id="total-wrapper">
      <thead>
        <tr>
          <th id="total-wrapper-col-1"></th>
          <th id="total-wrapper-col-2"></th>
          <th id="total-wrapper-col-3"></th>
        </tr>
        <tr>
          <td></td>
          <td><b>Subtotal:</b></td>
          <td>{{ subTotal | currency }}</td>
        </tr>
      </thead>
      <tbody>
        <div v-if="showTax">
          <tr>
            <td></td>
            <td><b>Tax 7.5%:</b></td>
            <td>{{ tax | currency }}</td>
          </tr>
        </div>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td colspan="2">
            <hr />
          </td>
        </tr>
        <tr>
          <td></td>
          <td><b>Total:</b></td>
          <td>{{ total | currency }}</td>
        </tr>
      </tfoot>
    </table>
    <br />
    <br />
    <table id="bottom-notice-wrapper">
      <thead>
        <tr>
          <th id="bottom-notice-wrapper-col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>Please make payment to:</b></td>
        </tr>
        <tr>
          <div class="text--primary py-3">
            {{ bankNumber }}<br />
            <p class="font-weight-bold text-caption">{{ bankName }}</p>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import jsPDF from "jspdf";

export default {
  name: "invoiceTemplate",
  props: {
    documentTitle: {
      type: String,
      default: "Invoice",
    },
    company_from: {
      type: String,
      default: "Company Name",
    },
    address_from: {
      type: String,
      default: "Street 1",
    },
    zip_from: {
      type: String,
      default: "12345",
    },
    city_from: {
      type: String,
      default: "City",
    },
    country_from: {
      type: String,
      default: "Country",
    },
    sender_custom1: {
      type: String,
      default: "Custom 1",
    },
    sender_custom2: {
      type: String,
      default: "Custom 2",
    },
    sender_custom3: {
      type: String,
      default: "Custom 3",
    },
    company_to: {
      type: String,
      default: "Company Name",
    },
    address_to: {
      type: String,
      default: "Street 1",
    },
    zip_to: {
      type: String,
      default: "12345",
    },
    city_to: {
      type: String,
      default: "City",
    },
    country_to: {
      type: String,
      default: "Country",
    },
    client_custom1: {
      type: String,
      default: "Custom 1",
    },
    client_custom2: {
      type: String,
      default: "Custom 2",
    },
    client_custom3: {
      type: String,
      default: "Custom 3",
    },
    invoicenumber: {
      type: String,
      default: "123456",
    },
    date: {
      type: String,
      default: "01.01.2020",
    },
    dueDate: {
      type: String,
      default: "01.01.2020",
    },
    lines: {
      type: [Array, Object],
      // default: () => [],
    },
    subTotal: {
      type: String,
      default: "0.00",
    },
    tax: {
      type: [String, Number],
      default: "0.00",
    },
    total: {
      type: String,
      default: "0.00",
    },
    bankName: {
      type: String,
    },
    bankNumber: {
      type: String,
    },
    showTax: {
      type: Boolean,
      default: true,
    },
    organizationLogo: String,
  },
  methods: {
    // download customer invoice
    downloadPDF() {
      // Clone the template and apply styling
      const clonedTemplate = this.$refs.invoiceTemplate.cloneNode(true);
      clonedTemplate.classList.add("invoice-template-download");

      // Convert the cloned template to HTML string
      const htmlString = clonedTemplate.outerHTML;

      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Generate the logo image as a data URL
      const logoImage = new Image();

      logoImage.onload = function () {
        // Add the logo image to the PDF document
        pdf.addImage(logoImage, "PNG", 10, 10, 50, 50);

        // Generate the PDF with the rest of the invoice template
        pdf.fromHTML(
          htmlString,
          70,
          10,
          {},
          function () {
            // Save the PDF
            pdf.save(this.company_from || "invoice.pdf");
          }.bind(this)
        );
      };

      logoImage.onerror = function () {
        console.error("Error loading logo image.");
        // Generate the PDF without the logo
        pdf.fromHTML(
          htmlString,
          10,
          10,
          {},
          function () {
            // Save the PDF
            pdf.save(this.company_from || "invoice.pdf");
          }.bind(this)
        );
      };

      logoImage.src = this.organizationLogo; // Set the logo image URL
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  font-family: "Inter Tight";
  /*letter-spacing: 0.0mm !important;*/
  /*text-align: justify;*/
  /*font-kerning: auto;*/
  /*line-height: 80%;*/
}

table {
  width: 100%;
}

tr {
  line-height: 110%;
}

td {
  /*border: 1px black solid;*/
  font-size: 10px;
  /*x-small;*/
  color: #525e6e;
}

b {
  color: #19283d;
}

#header-col-1 {
  width: 39%;
}

#header-col-2 {
  width: 61%;
}

/*#header-col-3 {*/
/*    width: 44%;*/
/*}*/

#logo-wrapper {
  vertical-align: top;
  text-align: left;
}

#logo {
  max-width: 121px;
  max-height: 100px;
}

#document-type-wrapper {
  text-align: right;
  line-height: 1.2;
  vertical-align: top;
}

#document-type {
  font-size: 20px;
  font-weight: bold;
  color: #19283d;
}

.total {
  font-size: 16px;
  font-weight: bold;
}

#receiver-details-wrapper td {
  vertical-align: top;
}

#receiver-details-wrapper td:nth-child(2) {
  text-align: right;
}

#receiver-details-wrapper-col-1 {
  width: 50%;
}

#receiver-details-wrapper-col-2 {
  width: 35%;
}

#receiver-details-wrapper-col-3 {
  width: 15%;
}

#products-table {
  border-collapse: collapse;
}

#products-table-col-1 {
  width: 52%;
}

#products-table-col-2 {
  width: 16%;
}

#products-table-col-3 {
  width: 16%;
}

#products-table-col-4 {
  width: 16%;
}

#products-table thead {
  background-color: #f6f7f9;
}

#products-table thead tr td:nth-child(2) {
  text-align: center;
  padding: 3px 0;
}

#products-table thead tr td:nth-child(3),
#products-table thead tr td:nth-child(4) {
  text-align: right;
  padding: 3px 0;
}

#products-table tbody tr td:nth-child(3),
#products-table tbody tr td:nth-child(4) {
  text-align: right;
  padding: 3px 0;
  padding-right: 5px;
}

#total-wrapper-col-1 {
  width: 60%;
}

#total-wrapper-col-2 {
  width: 24%;
}

#total-wrapper-col-3 {
  width: 16%;
}

#total-wrapper td {
  text-align: right;
}

#bottom-notice-wrapper-col-1 {
  width: 45%;
}

#bottom-notice-wrapper tbody tr td {
  text-align: left;

  #details-value-wrapper {
    min-width: 300px;
  }
}
</style>
